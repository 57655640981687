<template>
  <div>
         <div class="search">
        <div class="title">仓库列表</div>
        <hr />
      <el-form :inline="true"  class="top">
        <el-form-item label="仓库名称:">
            <el-input v-model="params.warehouseName" ></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
            <el-input v-model="params.phone" ></el-input>
        </el-form-item>
        <el-form-item label="联系人:">
            <el-input v-model="params.linkMan" ></el-input>
        </el-form-item>
            <!-- <el-form-item label="时间:">
                 <el-date-picker
                    :editable='false'
                    :picker-options="pickerOptions0"
                    value-format='yyyy-MM-dd HH:mm:ss'
                    v-model="date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
             </el-form-item> -->
         <el-form-item >
            <el-button type="primary"  @click="getList">查询</el-button>
            <el-button type="primary"   @click="reset">重置</el-button>
            <el-button type="primary"   @click="addBtn">新增</el-button>
        </el-form-item>
      
        </el-form>
     </div>
        <el-table
        :data="tableData"
        border
        style="width: 100%"
        align="center"
        @selection-change="handleSelectChange">
         <el-table-column type="selection" width="55"  align="center"> </el-table-column>
        <el-table-column type="index" width="50"   align="center"> </el-table-column>
        <el-table-column
        prop="warehouseName"
        label="仓库名称"
        
        align="center">
        </el-table-column>
        <el-table-column
        prop="linkMan"
        label="联系人"
        
        align="center">
        </el-table-column>

         <el-table-column
        prop="phone"
        label="手机号"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="address"
        label="地址"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="zipCode"
        label="邮政编码"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="addTime"
        label="注册时间"
        width="200"
        align="center">
        </el-table-column>
        <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
         
            <el-button type="primary"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
          
        </template>
        </el-table-column>
    </el-table>
         <div class="block">
         <div>
              <el-button type="primary" @click="alldel">批量删除</el-button>
         
         </div>
        <div>
              <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.currentPage"
            :page-sizes="[5,10,20]"
            :page-size="params.pageSize"
            layout="total,sizes,prev,pager,next, jumper"
            :total="total"
        >
        </el-pagination>
        </div>
     </div>
     <el-dialog
    :title="isEdit ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
   @close="handleClose">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="仓库名称" prop="warehouseName">
            <el-input v-model="addForm.warehouseName"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
            <el-input v-model="addForm.linkMan"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
            <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="streetCode">
            <el-select v-model="province" placeholder="请选择省" @change="selectChange($event,1)">
                <el-option v-for="item in provinceOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
          
            </el-select>
            <el-select v-model="city" placeholder="请选择城市" v-if="cityOptions.length>0" @change="selectChange($event,2)">
                <el-option v-for="item in cityOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
          
            </el-select>
            <el-select v-model="area" placeholder="请选择市区" v-if="areaOptions.length>0" @change="selectChange($event,3)">
                <el-option v-for="item in areaOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
          
            </el-select>
            <el-select v-model="addForm.streetCode" placeholder="请选择街道" v-if="streetOptions.length>0">
                <el-option v-for="item in streetOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
          
            </el-select>

        </el-form-item>
        <el-form-item label="详细地址" prop="address">
            <el-input v-model="addForm.address"></el-input>
        </el-form-item>
         <el-form-item label="邮政编码" prop="zipCode">
            <el-input v-model="addForm.zipCode"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import { wareList,addWare,delWare,wareDetails } from '../../api/selectMall.js'
import {
  getAllProvince,
  getCityByProvince,
  getAreaByCity,
  getStreetByArea,
} from "../../api/address";
export default {
    data() {
        return {
            dialogVisible: false,
            total: 0,
            isEdit: false,
        
            params: {
                currentPage: 1,
                pageSize: 5,
                warehouseName: '',
                phone: '',
                linkMan: '',
                // beginTime: '',
                // endTime: '',
            },
            ids:'',
            province:'',
            city:'',
            area:'',
            tableData: [],
            addForm: {
                warehouseId:0,
                warehouseName: '',
                linkMan: '',
                phone: '',
                streetCode: '',
                address:'',
                zipCode:'',
              
            },
            provinceOptions: [],
            cityOptions: [],
            areaOptions: [],
            streetOptions: [],
            addFormRules: {
                warehouseName: [
                    { required: true, message: '请输入仓库名称', trigger: 'blur' },
                   
                ],
                linkMan: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                    
                ],
                phone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                   
                ],
                streetCode: [
                    { required: true, message: '请选择地址', trigger: 'blur' },
                   
                ],
                area: [
                    { required: true, message: '请选择地址', trigger: 'blur' },
                   
                ],
                address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                   
                ],
                zipCode: [
                    { required: true, message: '请输入邮编', trigger: 'blur' },
                   
                ],
            },
            date: null,
            pickerOptions0: {
            disabledDate(time) {
                return time.getTime() > Date.now() - 8.64e6
            },
         }
        }
    },
    created(){
        this.getList()
        this.getallProvince()
    },
    methods: {
        handleSelectChange(val) {
            console.log(val)
            this.ids = val.map(item => item.warehouseId).join(',')
        },
        alldel(){
            if(!this.ids){
                this.$message({
                    message: '请选择要删除的仓库',
                    type: 'warning'
                })
                return
            }
             this.$confirm('此操作将永久删除选中仓库, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(async () => {
                     const {data} = await delWare({ids:this.ids})
                    if(data.code == 0){
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                            message: data.msg,
                            type: 'warning'
                        })
                    }
                }).catch(() => {
                   
                });
           
        },
       async getallProvince(){
           const {data} = await getAllProvince()
           if(data.code === 0){
               this.provinceOptions = data.data
           }
        },
      selectChange(e,type){
            this.addForm.streetCode = ''
            if(type === 1){
                this.areaOptions = []
                this.cityOptions = []
                this.streetOptions = []
              
              
                this.city = ''
                this.area = ''
               this.getAllCity(e)
            }else if(type === 2){
                
                 
                this.area = ''
                  this.areaOptions = []
                   this.streetOptions = []
             this.getAllArea(e)
            }else if(type === 3){
              
                this.streetOptions = []
               this.getAllStreet(e)
            }
        },
       async getAllCity(e){
             const {data} = await getCityByProvince({provinceCode:e})
                if(data.code == 0){
                    this.cityOptions = data.data
                }
        },
       async getAllArea(e){
              const {data} = await getAreaByCity({cityCode:e})
                if(data.code == 0){
                    this.areaOptions = data.data
                }
        },
       async getAllStreet(e){
              const {data} = await getStreetByArea({areaCode:e})
                if(data.code == 0){
                    this.streetOptions = data.data
                  
                }
        },
        async getList(){
            // this.params.beginTime = this.date ?  this.date[0] : ''
            // this.params.endTime = this.date ? this.date[1] : ''
            const {data} = await wareList(this.params)
            if(data.code == 0){
                this.tableData = data.list
                this.total = data.pagination.total
            }else{
                this.$message.warning(data.msg)
            }
        },
          handleSizeChange(e){
            this.params.pageSize = e
            this.getList()
        },
          // 页数改变
        handleCurrentChange(e){
            this.params.currentPage = e
            this.getList()
        },
        reset(){
           
            this.params.currentPage = 1
            this.params.pageSize = 5
            this.params.phone = ''
            this.params.warehouseName = ''
            this.params.linkMan = ''
            // this.params.beginTime = ''
            // this.params.endTime = ''
            // this.date = null
        },
        handleClose(){
          
            this.addForm.warehouseId = 0
            this.addForm.warehouseName = ''
            this.addForm.linkMan = ''
            this.addForm.phone = ''
            this.addForm.streetCode = ''
            this.addForm.address = ''
            this.addForm.zipCode = ''
              this.areaOptions = []
                this.cityOptions = []
                this.streetOptions = []
         
              
                this.city = ''
                this.area = ''

            this.$refs.addFormRef.resetFields()
              this.dialogVisible = false
        },
        addBtn(){
            this.isEdit = false
            this.dialogVisible = true
        },
        async edit(row){
             this.isEdit = true
            const {data} = await wareDetails({warehouseId:row.warehouseId})
            this.province = data.data.provinceCode
            this.city = data.data.cityCode
            this.area = data.data.areaCode
           this.addForm.warehouseId = row.warehouseId
           
            this.getAllCity(data.data.provinceCode)
            this.getAllArea(data.data.cityCode)
            this.getAllStreet(data.data.areaCode)
            Object.keys(this.addForm).forEach(key => {
                this.addForm[key] = data.data[key]
            })
           this.dialogVisible = true
        },
        remove(row){
           
             this.$confirm('此操作将永久删除该仓库, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                  const {data} = delWare({ids:row.warehouseId})
                  if(data.code == 0){
                      this.$message({
                          message: '删除成功',
                          type: 'success'
                      })
                      this.getList()
                  }
                }).catch(() => {
                     
            });
        },
        submitForm(){
            //  if(this.streetOptions.length<=0){
            //         this.addForm.streetCode = this.area || ""
            // }
            this.$refs.addFormRef.validate(async(valid) => {
                if (valid) {
                    
                   
                    // if(this.streetOptions.length<=0 && !this.area){
                    //     return  this.$message({
                    //         message:'请选择城市地址',
                    //         type: 'warning'
                    //     })
                    // }
                    //  if(this.streetOptions.length>0){
                    //       return  this.$message({
                    //         message:'请选择街道地址',
                    //         type: 'warning'
                    //     })
                    // }
                    if(this.isSubmit) return
                    this.isSubmit = true
                    const {data} = await addWare(this.addForm)
                    if(data.code == 0){
                        this.dialogVisible = false
                        this.getList()
                        this.$message({
                            message:this.isEdit ? '修改' : '新增' + '成功',
                            type: 'success'
                        })
                        this.dialogVisible = false
                    }else{
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.isSubmit = false
                    }, 500);
                }
            })
        }
    }
}
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top{
    margin-top: 20px;
}
.block{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>